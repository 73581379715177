import League, {
  LEAGUE_MODULE_NAMES,
  ENVIRONMENT,
  LeagueConfig,
} from '@leagueplatform/core';
import { ASSET_OVERRIDES } from 'common/asset-overrides';
import {
  TENANT_ID,
  APP_ID,
  MASONRY,
  AUTH0_DEFAULT_CLIENT_CONNECTION,
} from 'common/constants';
import { xoHealthTheme } from 'theme/theme';
import EN_STRING_OVERRIDES from 'locales/sdk-string-overrides/en.json';

const {
  VITE_GA_PROPERTY_ID,
  VITE_SEGMENT_KEY,
  VITE_WS_API_URL,
  VITE_API_URL,
  VITE_LEGACY_REST_API_URL,
  VITE_CONTENT_URL,
  VITE_AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID,
  VITE_APP_ENV,
} = import.meta.env;

const customMethodsConfig = {
  handleLink: async (url: string) => {
    await window.open(url, '_blank', 'noreferrer');
  },
};

const routesConfig = {
  [LEAGUE_MODULE_NAMES.wallet]: '/benefits',
  [LEAGUE_MODULE_NAMES.healthNeedPages]: '/health-need',
  [LEAGUE_MODULE_NAMES.onboarding]: '/onboarding',
};

export const config: LeagueConfig = {
  core: {
    clientId: APP_ID,
    tenantId: TENANT_ID,
    auth: {
      clientOptions: {
        domain: VITE_AUTH0_DOMAIN!,
        client_id: VITE_AUTH0_CLIENT_ID!,
        redirect_uri: window.location.origin,
        connection: AUTH0_DEFAULT_CLIENT_CONNECTION,
      },
      idleSessionLifetimeHours: 48,
    },
    api: {
      wsUrl: VITE_WS_API_URL!,
      url: VITE_API_URL!,
      legacyRestApi: VITE_LEGACY_REST_API_URL!,
    },
    contentUrl: VITE_CONTENT_URL!,
    appEnvironment: VITE_APP_ENV as ENVIRONMENT,
    analytics: {
      GAPropertyId: VITE_GA_PROPERTY_ID,
      segmentKey: VITE_SEGMENT_KEY,
    },
    customMethods: {
      handleLink: customMethodsConfig.handleLink,
    },
    routing: { isUsingLeagueRouting: true },
    i18n: {
      stringOverrides: {
        default: EN_STRING_OVERRIDES,
        en: EN_STRING_OVERRIDES,
      },
    },
    ui: {
      theme: xoHealthTheme,
      components: {
        pageHeader: {
          shape: 'line',
        },
      },
    },
  },
  assets: {
    overrides: ASSET_OVERRIDES,
  },
  claims: {
    featureFlags: {
      shouldShowClaimDetails: true,
      shouldShowDownloadClaims: true,
      shouldShowFilterChips: true,
    },
  },
  wallet: {
    showLandingPageClaimsEntry: true,
  },
  careDiscovery: {
    masonryAppId: {
      left: MASONRY.APP_ID_CARE_DISCOVERY_HOME,
    },
  },
};

// These are SDK initializers that have not yet been migrated to the new Config API.
export const initializeNonConfigSDKs = () => {
  League.overwriteRoutePaths(routesConfig);
};
