import { TEXT } from '@leagueplatform/web-common-components';

export const inputFieldData = [
  {
    name: 'firstName',
    inputType: TEXT,
    label: 'FIRST_NAME',
    disabled: true,
  },
  {
    name: 'lastName',
    inputType: TEXT,
    label: 'LAST_NAME',
    disabled: true,
  },
  {
    name: 'dateOfBirth',
    inputType: TEXT,
    label: 'DATE_OF_BIRTH',
    disabled: true,
  },
  {
    name: 'email',
    inputType: TEXT,
    label: 'EMAIL_ADDRESS',
    disabled: true,
  },
  {
    name: 'phoneNumber',
    inputType: TEXT,
    label: 'PHONE_NUMBER',
    disabled: true,
  },
];
