export const APP_MANAGED_ROUTES = {
  LANDING_PAGE: '/',
  HOME: '/home',
  WALLET: '/benefit',
  SIGN_IN: '/sign-in',
  SIGN_OUT: '/sign-out',
  GET_CARE: '/get-care',
  PERSONAL_INFO: '/settings/personal-information',
  COMMUNICATION_PREFERENCES: '/communication-preferences',
  HEALTH_JOURNEY: '/health-journey',
  ABOUT_ME: '/about-me',
  ADVOCATE: '/advocate',
};
