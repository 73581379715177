import React, { useEffect } from 'react';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { LoadingIndicator } from '@leagueplatform/web-common-components';

export const AuthSignOut = () => {
  useEffect(() => {
    StandaloneAuth.client.logout({
      returnTo: window.location.origin,
    });
  }, []);

  return <LoadingIndicator />;
};
