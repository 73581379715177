import React from 'react';
import { Formik, Form } from 'formik';
import { useIntl } from '@leagueplatform/locales';
import { isPhoneNumber } from '../../utilities/is-phone-number';
import { UserInfoDataProps, UserProfileFormValues } from '../../common/types';
import { FormContent } from './form-content.component';

/**
 * While the API returns the user profile data as a nested object inside a larger
 * object, we want to flatten it for use as a collection of fields in this form,
 * so we'll remove the `userProfile` property from the parent and spread its contents
 * *into* the parent instead.
 */

export const UserProfileForm: React.FC<UserInfoDataProps> = ({
  data,
  inputFieldData,
}: UserInfoDataProps) => {
  const { formatMessage } = useIntl();

  const validate = (values: UserProfileFormValues) => {
    let errors: UserProfileFormValues = {};

    if (values?.phoneNumber && !isPhoneNumber(values.phoneNumber)) {
      errors = {
        phoneNumber: formatMessage({ id: 'INVALID_PHONE_NUMBER' }),
      };
    }
    return errors;
  };

  const initialFormValues: UserProfileFormValues = {
    ...data.userProfile,
    email: data?.email,
    phoneNumber: data?.phoneNumber,
    dateOfBirth: data?.userProfile?.dateOfBirth,
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validate={validate}
      onSubmit={() => {}}
    >
      {(formProps) => (
        <Form>
          <FormContent formProps={formProps} inputFieldData={inputFieldData} />
        </Form>
      )}
    </Formik>
  );
};
