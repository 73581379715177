import React from 'react';
/* eslint-disable react/jsx-props-no-spreading */
import { Box } from '@leagueplatform/genesis-core';
import { InputFieldDataModel } from '../../common/types';
import { Input } from '../Input.component';

export const FormContent = ({ formProps, inputFieldData }: any) => (
  <Box>
    {inputFieldData.map((input: InputFieldDataModel) => (
      <Input key={input.name} data={input} {...formProps} />
    ))}
  </Box>
);
