import React, { Suspense } from 'react';

import {
  AppNavBarComponent,
  APP_NAV_DEFAULT_HEIGHT,
} from 'components/header-nav/Nav';
import { Box, GDSBoxProps, GDSStyleObject } from '@leagueplatform/genesis-core';
import { useScrollTopOnLocationChange } from '@leagueplatform/web-common';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { ErrorState } from '@xo-health-web/shared-module';
import { ObservabilityErrorBoundary } from '@leagueplatform/observability';

type BasePageProps = GDSBoxProps<'div'> & {
  css?: GDSStyleObject;
  isPrivatePage?: boolean;
};

export const BasePage: React.FC<BasePageProps> = ({
  children,
  isPrivatePage = true,
  css,
  ...boxProps
}) => {
  useScrollTopOnLocationChange();

  const mainContentMinHeight = isPrivatePage
    ? `calc(100vh - ${APP_NAV_DEFAULT_HEIGHT}px)`
    : '100vh';

  return (
    <>
      {isPrivatePage && <AppNavBarComponent />}
      <Box
        as="main"
        css={{
          position: 'relative',
          flex: '1',
          minHeight: mainContentMinHeight,
          overflowX: 'hidden',
          ...css,
        }}
        {...boxProps} // eslint-disable-line
      >
        <Suspense fallback={<LoadingIndicator />}>
          <ObservabilityErrorBoundary
            fallback={
              <ErrorState
                headingId="STR_ERROR_STATE_HEADING_GENERIC"
                bodyId="STR_ERROR_STATE_BODY_API"
                buttonId="STR_RELOAD"
                styleProps={{
                  css: {
                    height: mainContentMinHeight,
                  },
                }}
              />
            }
            errorContext={{
              context: {
                Context: { location: 'Base Page ErrorBoundary' },
              },
            }}
          >
            {children}
          </ObservabilityErrorBoundary>
        </Suspense>
      </Box>
    </>
  );
};
