export const MASONRY = {
  APP_ID_HOME_PAGE: 'xohealth_home',
  API_VERSION: 2,
  APP_ID_CARE_DISCOVERY_HOME: 'xohealth_care',
  APP_ID_ADVOCATE_PAGE: 'xohealth_advocate',
  MODULE_KEY_CLAIMS_HOMEPAGE: 'claims',
  CUSTOM_WIDGET_TYPE_CLAIMS: 'recent_claims_cards',
  MODULE_KEY_HEALTH_JOURNEY: 'health_journey',
  CUSTOM_WIDGET_TYPE_HEALTH_JOURNEY: 'health_journey',
};
export const APP_ID = 'xohealth-web';
/**
 * @deprecated TEMPORARY while we transition from client-set TenantID to server-set
 */
export const TENANT_ID = 'xohealth';
export type FirebaseRemoteConfigValue = boolean | string | number;

export const AUTH0_DEFAULT_CLIENT_CONNECTION = 'xohealth';
// Feature Flag Names
export enum FEATURE_FLAGS {
  PLACEHOLDER_FLAG = 'do_not_use', // Do not remove this non-functional flag: keep feature flag logic present even when we have 0 active flags
  /* Add active flags below this line */
  XOHEALTH_FLAGSAMPLE = 'rel_xohealth_flagSample',
}

export const SENTRY_CONSTANTS = {
  OWNERSHIP_TEAM: 'xohealth-solutions',
};

export const AUTH0_ERROR_TYPES = {
  LOGIN_REQUIRED: 'login_required',
};
