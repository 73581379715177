import { useMutation } from 'react-query';
import { uploadFile } from '@leagueplatform/league-content-api';
import { captureAppError } from '@leagueplatform/observability';
import { SENTRY_CONSTANTS } from '../common/constants';

/**
 *
 * @name useUploadFile
 * @desc React hook to upload a file and handle error.
 * @returns Function that uploads a file and returns a file reference ID.
 */
export const useUploadFile = () => {
  const uploadFileMutation = useMutation((file: File) => uploadFile(file));

  return async (file: File) => {
    try {
      const response = await uploadFileMutation.mutateAsync(file);
      const referenceId: string = response?.reference_id;

      if (!referenceId) throw new Error();
      return referenceId;
    } catch (error) {
      if (error instanceof Error) {
        captureAppError(error, {
          ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
          errorName: 'Error uploading a file',
          context: {
            Context: {
              fileSize: file?.size,
              fileType: file?.type,
            },
          },
        });
      }
      return null;
    }
  };
};
