import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';

export enum NavIds {
  HOME = 'HOME',
  ADVOCATE = 'ADVOCATE',
  BENEFITS = 'BENEFITS',
  CARE = 'GET_CARE',
  JOURNEY = 'JOURNEY',
}

export enum LinkType {
  NAV = 'navLink',
  LINK = 'link',
}

type NavItem = {
  to:
    | string
    | {
        search: string;
        pathname: string;
        state: unknown;
        hash: string;
        key?: string | undefined;
      };
};

export interface NavLink extends NavItem {
  message_id: NavIds;
  type: LinkType;
}

export const useNavLinksList = () => {
  const navLinksList: NavLink[] = [
    {
      to: APP_MANAGED_ROUTES.HOME,
      message_id: NavIds.HOME,
      type: LinkType.NAV,
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.wallet),
      message_id: NavIds.BENEFITS,
      type: LinkType.NAV,
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.careDiscovery),
      message_id: NavIds.CARE,
      type: LinkType.NAV,
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
      message_id: NavIds.JOURNEY,
      type: LinkType.NAV,
    },
    {
      to: APP_MANAGED_ROUTES.ADVOCATE,
      message_id: NavIds.ADVOCATE,
      type: LinkType.NAV,
    },
  ];

  return {
    navLinks: navLinksList,
  };
};
