import ErrorImage from 'assets/error-rubiks-cube.svg';
import FeatureHighlightsBackground from 'assets/feature-highlights-background.png';

const COMMON_OVERRIDES = {
  WEB_COMMON_ERROR_STATE_IMAGE: ErrorImage,
};

const ONBOARDING_OVERRIDES = {
  FEATURE_HIGHLIGHT_BACKGROUND: FeatureHighlightsBackground,
};

export const ASSET_OVERRIDES = {
  ...ONBOARDING_OVERRIDES,
  ...COMMON_OVERRIDES,
};
