import React from 'react';
import { Box, UtilityText } from '@leagueplatform/genesis-core';
import { InputField } from '@leagueplatform/web-common-components';

export const Input = ({
  data,
  paddingY = '$one',
  ...inputHandlerProps
}: any) => (
  <Box css={{ paddingBlock: paddingY, flexGrow: 1, flexBasis: 0 }}>
    <InputField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...data}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...inputHandlerProps}
    />
    {inputHandlerProps.errors[data.name] && (
      <UtilityText
        as="p"
        size="sm"
        css={{
          marginBlockStart: '$quarter',
          color: '$onSurfaceTextCritical',
        }}
      >
        {inputHandlerProps.errors[data.name]}
      </UtilityText>
    )}
  </Box>
);
