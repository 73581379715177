import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { BasePage } from 'components/BasePage';
import { OnboardingFeatureHighlights } from 'components/onboarding-feature-highlights/onboarding-feature-highlights.component';
import { useOnboarding } from 'hooks/use-onboarding.hook';

export const PrivateRoutesContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { onOnboardingComplete, shouldShowFeatureHighlights, isLoading } =
    useOnboarding();

  if (isLoading) {
    return <LoadingIndicator />;
  }
  if (shouldShowFeatureHighlights) {
    return (
      <OnboardingFeatureHighlights
        onOnboardingComplete={onOnboardingComplete}
      />
    );
  }
  return <BasePage>{children}</BasePage>;
};
