import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Box, ParagraphText, UtilityText } from '@leagueplatform/genesis-core';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { EXTERNAL_LINKS } from '../../../common/links';
import { BodyContainer } from '../../body-container/body-container.component';
import { CheckConfirmation } from '../../check-confirmation/check-confirmation.component';
import { CaptionWithLink } from '../../caption-with-link.component/caption-with-link.component';
import { useCommunicationPreferences } from '../../../hooks/use-communication-preferences.hook';

export const CommunicationPreferencesPage = () => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'COMMUNICATION_PREFERENCES' }));

  const [
    { allowEmailNotification, isLoading, isWaiting, email, showEmail },
    { setEmailNotification },
  ] = useCommunicationPreferences();

  return (
    <BodyContainer css={{ maxWidth: '816px' }}>
      <CheckConfirmation
        label={formatMessage({
          id: 'COMMUNICATION_PREFERENCES_ALLOW_EMAIL',
        })}
        name="allowEmailNotification"
        checked={allowEmailNotification}
        handleCheck={setEmailNotification}
        loading={isLoading || isWaiting === 'email'}
        ariaDisabled={!!isWaiting}
      />
      <Box aria-live="polite" css={{ paddingX: '42px' }}>
        {showEmail && (
          <Box>
            <ParagraphText css={{ '&&': { marginBlockEnd: 0 } }}>
              <UtilityText
                css={{
                  fontWeight: '$subtitleTwo',
                  fontSize: '$subtitleTwo',
                  lineHeight: '$subtitleTwo',
                }}
              >
                {formatMessage({
                  id: 'USER_NOTIFIED_EMAIL_SUBTITLE',
                })}
              </UtilityText>
              <br />
              <UtilityText
                css={{
                  fontWeight: '$bodyTwo',
                  fontSize: '$bodyTwo',
                  lineHeight: '$bodyTwo',
                }}
              >
                {email}
              </UtilityText>
              <br />
              <UtilityText
                css={{
                  fontWeight: '$label',
                  fontSize: '$label',
                  lineHeight: '$label',
                }}
              >
                {formatMessage({
                  id: 'EDIT_EMAIL',
                })}
              </UtilityText>
            </ParagraphText>
          </Box>
        )}
      </Box>
      <Box css={{ paddingX: '42px' }}>
        <CaptionWithLink
          messageId="EMAIL_LEGAL_DISCLAIMER"
          linkHref={EXTERNAL_LINKS.TERMS_OF_USE}
          linkTextId="VISIT_OUR_TERMS"
          css={{
            fontWeight: '$label',
            fontSize: '$label',
            lineHeight: '$label',
          }}
        />
      </Box>
    </BodyContainer>
  );
};
