import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { StackLayout } from '@leagueplatform/genesis-core';
import { useHistory } from '@leagueplatform/routing';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { HeroSection } from 'components/hero-section/hero-section.component';
import { CommunicationPreferencesPage } from '@xo-health-web/shared-module';

export const CommunicationPreferences = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  useDocumentTitle(formatMessage({ id: 'COMMUNICATION_PREFERENCES' }));

  return (
    <MainPageContainer width="100%">
      <HeroSection
        title={formatMessage({ id: 'COMMUNICATION_PREFERENCES' })}
        goBack={history.goBack}
      />
      <StackLayout>
        <CommunicationPreferencesPage />
      </StackLayout>
    </MainPageContainer>
  );
};
