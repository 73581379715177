import { useEffect, useState } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { COMMUNICATION_OPTIONS } from '../common/constants';
import {
  usePersonalInformation,
  useUpdateUserProfile,
} from './use-personal-information.hook';

export const useCommunicationPreferences = () => {
  const { formatMessage } = useIntl();
  const updateUserProfile = useUpdateUserProfile();
  const { data: personalInformation, isLoading: personalInformationIsLoading } =
    usePersonalInformation();

  const isLoading = personalInformationIsLoading;

  const [allowEmailNotification, setAllowEmailNotification] = useState<
    undefined | boolean
  >();

  const [isWaiting, setIsWaiting] = useState('');

  const isEmailNotificationEnabled =
    !!personalInformation?.userProfile?.optedIntoMarketingCommunications;

  useEffect(() => {
    if (isLoading === false && allowEmailNotification === undefined) {
      setAllowEmailNotification(isEmailNotificationEnabled);
    }
  }, [allowEmailNotification, isEmailNotificationEnabled, isLoading]);

  const setEmailNotification = async () => {
    if (isWaiting) return;

    setIsWaiting(COMMUNICATION_OPTIONS.EMAIL);

    const optedIntoMarketingCommunications = !allowEmailNotification;

    try {
      setAllowEmailNotification((prev) => !prev);
      await updateUserProfile({
        optedIntoMarketingCommunications,
      });
    } catch (err) {
      setAllowEmailNotification((prev) => !prev);
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: formatMessage({ id: 'EMAIL_NOTIFICATION_PREFERENCE_ERR' }),
      });
    }
    setIsWaiting('');
  };
  return [
    {
      allowEmailNotification,
      isLoading,
      isWaiting,
      email: personalInformation?.email,
      showEmail:
        !!allowEmailNotification && !(isLoading || isWaiting === 'email'),
    },
    { setEmailNotification },
  ];
};
