import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  MainPageContainer,
  LoadingIndicator,
} from '@leagueplatform/web-common-components';
import { Box, StackLayout } from '@leagueplatform/genesis-core';
import { useHistory } from '@leagueplatform/routing';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { HeroSection } from 'components/hero-section/hero-section.component';
import { inputFieldData } from 'utils/input-field-data';
import {
  usePersonalInformation,
  UserProfileForm,
  EditProfilePhoto,
  EditDisplayName,
} from '@xo-health-web/shared-module';

export const PersonalInformationPage = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  useDocumentTitle(formatMessage({ id: 'PERSONAL_INFO' }));

  const { data, isLoading } = usePersonalInformation();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <MainPageContainer width="100%">
      <HeroSection
        title={formatMessage({ id: 'PERSONAL_INFO' })}
        goBack={history.goBack}
      />
      <StackLayout horizontalAlignment="center">
        <Box
          css={{
            width: '100%',
            maxWidth: 370,
          }}
        >
          <EditProfilePhoto />
          <EditDisplayName />
          <UserProfileForm data={data} inputFieldData={inputFieldData} />
        </Box>
      </StackLayout>
    </MainPageContainer>
  );
};
