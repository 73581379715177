import { StackLayout, styled } from '@leagueplatform/genesis-core';

export const FullScreenContainer = styled(StackLayout, {
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  top: '0',
  left: '0',
  zIndex: '100',
  overflowY: 'auto',
});
