import React from 'react';
import { GDSStyleObject, StackLayout } from '@leagueplatform/genesis-core';

interface Props {
  children: React.ReactNode;
  css?: GDSStyleObject;
}

export const BodyContainer = ({ children, css }: Props) => (
  <StackLayout
    css={{
      backgroundColor: '$surfaceBackgroundPrimary',
      gap: '$half',
      padding: '$oneAndHalf',
      border: 'thin solid $onSurfaceBorderSubdued',
      borderRadius: '$medium',
      maxWidth: 1048,
      marginInlineStart: '$none',
      '@desktop': { marginInlineStart: '$three' },
      '@mobile': { padding: '$one' },

      ...css,
    }}
  >
    {children}
  </StackLayout>
);
