import OnboardingRoutesWithProvider from '@leagueplatform/onboarding';
import { TENANT_ID } from 'common/constants';
import { getFeatureHighlightsContentfulId } from 'common/env';

export const OnboardingFeatureHighlights = ({
  onOnboardingComplete,
}: {
  onOnboardingComplete: (error?: Error) => void;
}) => (
  <OnboardingRoutesWithProvider
    contentfulId={getFeatureHighlightsContentfulId()}
    tenantId={TENANT_ID}
    onOnboardingComplete={onOnboardingComplete}
  />
);
