import { BoxProps } from '@leagueplatform/genesis-commons';
import { usePreferredFirstName } from 'hooks/use-preferred-first-name.hook';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';

const userDropdownConfig = [
  [
    {
      to: APP_MANAGED_ROUTES.PERSONAL_INFO,
      text: 'PERSONAL_INFO',
    },
    {
      to: APP_MANAGED_ROUTES.COMMUNICATION_PREFERENCES,
      text: 'COMMUNICATION_PREFERENCES',
    },
  ],
  [
    {
      to: APP_MANAGED_ROUTES.SIGN_OUT,
      text: 'LOG_OUT',
      linkProps: {
        color: 'onSurface.text.critical',
      } as BoxProps,
    },
  ],
];

export const useUserDropdownComponent = () => {
  const { data, isLoading, error } = usePreferredFirstName();

  const dropdownMenuItemProps = {
    preferredFirstName: data.preferredFirstName,
    menuItemsConfig: userDropdownConfig,
  };

  return { data: dropdownMenuItemProps, isLoading, error };
};
